import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import size from 'lodash/fp/size'
import equals from 'lodash/fp/equals'
import first from 'lodash/fp/first'

import { formatPriceToFixed as formatPrice } from '@tofu/shared/utils/format-currency'

import { TQueryResponse } from './get-cart-success-toast.types'

const getAmount = get('amount')
const getDiscountType = get('discount_type')
const getDiscountCodes = get('discount_codes')
const isPercentage = equals('PERCENTAGE')
const isFixedAmount = equals('FIXED_AMOUNT')

export const hasDiscountCodes = flow(getDiscountCodes, size, Boolean)

const getFirstDiscountCodesItem = flow(getDiscountCodes, first)
const getDiscountCodeType = flow(getFirstDiscountCodesItem, getDiscountType)

export const getDiscountCodeAmount = flow(getFirstDiscountCodesItem, getAmount)
export const getFormattedDiscountCodeAmount = flow(
  getFirstDiscountCodesItem,
  getAmount,
  formatPrice
)

export const isDiscountTypeAPercentage = flow(getDiscountCodeType, isPercentage)
const isDiscountTypeAFixedAmount = flow(getDiscountCodeType, isFixedAmount)

export const isDisountPercentage = ({ data, variables }: TQueryResponse) =>
  hasDiscountCodes(variables) &&
  hasDiscountCodes(data) &&
  isDiscountTypeAPercentage(data)

export const isDisountFixedAmount = ({ data, variables }: TQueryResponse) =>
  hasDiscountCodes(variables) &&
  hasDiscountCodes(data) &&
  isDiscountTypeAFixedAmount(data)
