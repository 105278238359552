/* istanbul ignore file */
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useDiscountCheck } from '@tofu/shop/hooks/use-discount-check'
import { TDiscountCheck } from './discount-check.types'

export const DiscountCheck: TDiscountCheck = () => {
  useDiscountCheck()

  return null
}
