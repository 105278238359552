/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useToast, UseToastOptions } from '@chakra-ui/toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TCart } from '@tofu/shared/types/cart'
import { updateCart } from '@tofu/shared/data-access/cart'
import { useSession } from '@tofu/apps/shop/providers/session'
import { useCartCreate } from '@tofu/shop/hooks/use-cart-create'
import { getCartErrorToast, getCartSuccessToast } from '@tofu/shared/utils/cart'

const defaultErrorToast: UseToastOptions = {
  duration: 3000,
  status: 'warning',
  title: 'Uh oh...',
  description: 'We cannot update your cart. Please try again.'
}

// UseMutationOptions isn't working here for some reason
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCartUpdate = (mutationOptions?: any) => {
  const toast = useToast()
  const { data: sessionData } = useSession()
  const { mutateAsync: createCart } = useCartCreate()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { cartToken } = sessionData || {}
  const queryClient = useQueryClient()

  const mutationFn = async (update: TCart) => {
    let token = cartToken

    if (!token) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cart: any = await createCart()
      token = cart.token
    }

    return updateCart(token, update)
  }

  return useMutation({
    mutationFn,
    onError: (
      { error_code, minimum_spend },
      variables: Record<string, unknown>
    ) => {
      const errorToast =
        getCartErrorToast(error_code, { ...variables, minimum_spend }) ||
        defaultErrorToast
      toast(errorToast)

      queryClient.invalidateQueries(['getCart'])
    },
    onSuccess: (data: TCart, variables: TCart) => {
      const successToast = getCartSuccessToast({
        data,
        variables
      })

      // TODO: need to remove this from the hook
      if (successToast) {
        toast(successToast)
      }
      queryClient.invalidateQueries(['getCart'])

      return data
    },
    ...mutationOptions
  })
}
