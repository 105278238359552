import includes from 'lodash/fp/includes'
import { formatPrice } from '@tofu/shared/utils/format-currency'

import {
  TErrorResponseItem,
  TGetCartErrorToast
} from './get-cart-error-toast.types'

const getErrorRepsonseList: TErrorResponseItem = ({
  discount_codes,
  minimum_spend
}: {
  discount_codes?: { code: string }[]
  minimum_spend?: number
}) => {
  const discountCode = discount_codes?.[0]?.code || ''
  const discountMinimumSpend = minimum_spend
    ? formatPrice(minimum_spend / 100)
    : 0

  return [
    {
      predicate: includes('discount_not_found'),
      toastOptions: {
        duration: 3000,
        status: 'warning',
        title: 'Uh oh...',
        description: 'We cannot apply that code. Please try again.'
      }
    },
    {
      predicate: includes('discount_expired'),
      toastOptions: {
        isClosable: true,
        duration: null,
        status: 'error',
        description: `Sorry, the code ${discountCode} has expired.`
      }
    },
    {
      predicate: includes('discount_new_customers_only'),
      toastOptions: {
        isClosable: true,
        duration: null,
        status: 'error',
        description: `Sorry, the code ${discountCode} won't work with this order.`
      }
    },
    {
      predicate: includes('discount_minimum_spend'),
      toastOptions: {
        isClosable: true,
        duration: null,
        status: 'error',
        description: discountMinimumSpend
          ? `Sorry, the code ${discountCode} has a minimum spend of ${discountMinimumSpend}.`
          : `Sorry, your basket is below the minimum spend for the code ${discountCode}.`
      }
    },
    {
      predicate: includes('discount_valid_on_one_off_only'),
      toastOptions: {
        isClosable: true,
        duration: null,
        status: 'error',
        description: `Sorry, the code ${discountCode} is only valid for one time orders. Please select One Time and try again.`
      }
    },
    {
      predicate: includes('discount_valid_on_subscription_only'),
      toastOptions: {
        isClosable: true,
        duration: null,
        status: 'error',
        description: `Sorry, the code ${discountCode} is only valid for subscription orders. Please select Subscribe & Save and try again.`
      }
    },
    {
      predicate: includes('discount'), // catch all for discount errors
      toastOptions: {
        isClosable: true,
        duration: null,
        status: 'error',
        description: `We cannot apply that code (${discountCode}). Please try again.`
      }
    }
  ]
}

export const getCartErrorToast: TGetCartErrorToast = (
  errorResponse,
  additionalData = {}
) => {
  const errorRepsonseList = getErrorRepsonseList(additionalData)
  const errorResponseItem = errorRepsonseList.find(({ predicate }) =>
    predicate(errorResponse)
  )

  return errorResponseItem?.toastOptions
}
