import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { Box } from '@tofu/shared/ui/atoms/box'

const Accordions = dynamic(() => import('./accordions'))
const Checklist = dynamic(() => import('./checklist'))
const ComparisonTable = dynamic(() => import('./tables/comparisonTable'))
const Discount = dynamic(() => import('./discount'))
const DynamicHeroTransparentImage = dynamic(() =>
  import('./hero/dynamicHeroTransparentImage')
)
const EmbedScript = dynamic(() => import('./media/embedScript'))
const FourColumn = dynamic(() => import('./grid/fourColumn'))
const FullImage = dynamic(() => import('./grid/fullImage'))
const FullText = dynamic(() => import('./grid/fullText'))
const HeroImage = dynamic(() => import('./hero/heroImage'))
const HeroText = dynamic(() => import('./hero/heroText'))
const HeroTransparentImage = dynamic(() =>
  import('./hero/heroTransparentImage')
)
const HeroTwoColumn = dynamic(() => import('./hero/heroTwoColumn'))
const IconBanner = dynamic(() => import('./iconBanner'))
const Lifecycle = dynamic(() => import('./lifecycle'))
const OneColumn = dynamic(() => import('./grid/oneColumn'))
const PricingTable = dynamic(() => import('./tables/pricingTable'))
const SliceProductCarousel = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-product-carousel').then(
    (mod) => mod.SliceProductCarousel
  )
)

const SliceTestimonials = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-testimonials').then(
    (mod) => mod.SliceTestimonials
  )
)

const SliceSpacing = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-spacing').then(
    (mod) => mod.SliceSpacing
  )
)

const SliceHero = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-hero').then((mod) => mod.SliceHero)
)

const SliceHeroMelissaHemsley = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-hero-melissa-helmsley').then(
    (mod) => mod.SliceHeroMelissaHemsley
  )
)

const SliceButton = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-button').then((mod) => mod.SliceButton)
)

const SliceText = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-text').then((mod) => mod.SliceText)
)

const SliceTextBanner = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-text-banner').then(
    (mod) => mod.SliceTextBanner
  )
)

const SliceImageGrid = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-image-grid').then(
    (mod) => mod.SliceImageGrid
  )
)

const SlicePurchaseTypeCarousel = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-purchase-type-carousel').then(
    (mod) => mod.SlicePurchaseTypeCarousel
  )
)

const SliceImageAndTextList = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/organisms/slice-image-and-text-list').then(
    (mod) => mod.SliceImageAndTextList
  )
)
const TextBox = dynamic(() => import('./textBox'))
const ThreeColumn = dynamic(() => import('./grid/threeColumn'))
const Trustpilot = dynamic(() => import('../common/trustpilot'))
const TwoColumn = dynamic(() => import('./grid/twoColumn'))
const TwoColumnOverlapping = dynamic(() =>
  import('./grid/twoColumnOverlapping')
)

const VideoBlock = dynamic(() => import('./media/videoBlock'))

const Sections = ({ sections = [] }) => (
  <Box>
    {sections.map((slice, index) => {
      if (!slice) return null

      const { primary, items = [], slice_type } = slice

      switch (slice_type) {
        case 'accordion':
          return <Accordions {...primary} key={index} items={items} />
        case 'hero':
          return <SliceHero {...primary} key={index} />
        case 'testimonials_slider':
          return <SliceTestimonials key={index} {...slice} />
        case 'dynamic_hero_transparent_image':
          return (
            <DynamicHeroTransparentImage
              {...primary}
              key={index}
              dynamic_content_list={items}
            />
          )
        case 'icon_banner':
          return <IconBanner {...primary} key={index} list_items={items} />
        case 'checklist':
          return <Checklist {...primary} key={index} checklist={items} />
        case 'comparison_table':
          return (
            <ComparisonTable {...primary?.comparison_table?.data} key={index} />
          )
        case 'discount':
          return <Discount {...primary} key={index} />
        case 'embed_script':
          return <EmbedScript {...primary} key={index} />
        case 'four_column':
          return <FourColumn {...primary} key={index} />
        case 'full_image':
          return <FullImage {...primary} key={index} />
        case 'full_text':
          return <FullText {...primary} key={index} />
        case 'hero_image':
          return <HeroImage {...primary} key={index} />
        case 'hero_transparent_image':
          return <HeroTransparentImage {...primary} key={index} />
        case 'hero_text':
          return <HeroText {...primary} key={index} />
        case 'hero_two_column':
          return <HeroTwoColumn {...primary} key={index} />
        case 'lifecycle':
          return <Lifecycle {...primary} key={index} lifecycle={items} />
        case 'press':
          return <ThreeColumn hideCaption {...primary} key={index} />
        case 'pricing_table':
          return <PricingTable {...primary} key={index} items={items} />
        case 'product_carousel':
          return <SliceProductCarousel {...primary} items={items} key={index} />
        case 'image_grid':
          return <SliceImageGrid {...slice} key={index} />
        case 'purchase_type_carousel':
          return <SlicePurchaseTypeCarousel {...slice} key={index} />
        case 'spacing':
          return <SliceSpacing {...slice} key={index} />
        case 'one_column':
          return <OneColumn {...primary} key={index} />
        case 'text_box':
          return <TextBox {...primary} key={index} />
        case 'text':
          return <SliceText {...primary} key={index} />
        case 'button':
          return <SliceButton {...primary} key={index} />
        case 'text_banner':
          return <SliceTextBanner {...slice} key={index} />
        case 'three_column':
          return <ThreeColumn {...primary} key={index} />
        case 'two_column':
          if (primary?.variant && primary?.variant === 'overlapping') {
            return <TwoColumnOverlapping {...primary} key={index} />
          }
          return <TwoColumn {...primary} key={index} />
        case 'trustpilot':
          return <Trustpilot {...primary} key={index} />
        case 'video_block':
          return <VideoBlock {...primary} key={index} />
        case 'image_and_text_list':
          return <SliceImageAndTextList {...primary} items={items} />
        case 'melissa_hemsley_hero':
          return <SliceHeroMelissaHemsley {...primary} />
        default:
          return null
      }
    })}
  </Box>
)

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Sections
