import { useMutation } from '@tanstack/react-query'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { createCart } from '@tofu/apps/shop/services/cart'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useSession } from '@tofu/apps/shop/providers/session'

// UseMutationOptions dosen't seem to be working here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCartCreate = (options?: any) => {
  const { setCookies } = useSession()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mutationFn = async (data: any) => {
    try {
      const response = await createCart(data)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCookies('session_cart_token', response.token)
      return response
    } catch {
      throw new Error('Error creating cart')
    }
  }

  return useMutation({
    mutationFn,
    ...options
  })
}
