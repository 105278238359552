import find from 'lodash/fp/find'

import {
  TQueryResponse,
  TSuccessfulResponseItem
} from './get-cart-success-toast.types'

import {
  getFormattedDiscountCodeAmount,
  getDiscountCodeAmount,
  isDisountPercentage,
  isDisountFixedAmount
} from './get-cart-success-toast.utils'

const successfulRepsonseList: TSuccessfulResponseItem[] = [
  {
    predicate: isDisountPercentage,
    toastOptions: ({ data }: TQueryResponse) => ({
      duration: 3000,
      status: 'success',
      title: 'Hooray!',
      description: `We have applied a ${getDiscountCodeAmount(data)}% discount`
    })
  },
  {
    predicate: isDisountFixedAmount,
    toastOptions: ({ data }: TQueryResponse) => ({
      duration: 3000,
      status: 'success',
      title: 'Hooray!',
      description: `We have taken £${getFormattedDiscountCodeAmount(
        data
      )} off your order`
    })
  }
]

const findSuccessByResponse = (
  queryResponse: TQueryResponse
): TSuccessfulResponseItem | null =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  find(({ predicate }) => predicate(queryResponse))(successfulRepsonseList)

export const getCartSuccessToast = (queryResponse: TQueryResponse) => {
  return findSuccessByResponse(queryResponse)?.toastOptions(queryResponse)
}
